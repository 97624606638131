<!--suppress CssUnusedSymbol -->
<template>
  <div class="flex-grow overflow-x-hidden sm:m-2" :class="minimize ? 'minimized' : ''">
    <div v-if="tournament.master_postal_id">
      These results are only for the local scores of this postal.
      <a class="text-blue-600 cursor-pointer" :href="`/tournaments/${tournament.master.slug}`">
        Click here to view the global tournament
      </a>
    </div>
    <div v-if="!showLadders">
      <team-club-results-links v-if="!fullBreakdown" :teams="teams"/>
      <result-filters v-if="showFilters" :tournament="tournament"/>
      <result-form-switches v-if="!selectedCompetitor && fullBreakdown"/>
    </div>
    <result-options :hasLadders="tournament.ladders.length > 0" @refresh="refresh"/>
    <div class="bg-red-400 my-2 p-2 flex justify-center" v-if="willDropRounds">
      This league drops the lowest
      {{ tournament.dropped_rounds === 1 ? 'round' : tournament.dropped_rounds + ' rounds' }}.
      Dropped results are indicated by a red background.
    </div>
    <ladder-results v-if="showLadders" :tournament-in="tournament"/>
    <breakdown-results
      v-else-if="fullBreakdown"
      :tournament-in="tournament"
      :competitors="competitors"
      :place-list="placeList"
      :user="user"/>
    <overall-results
      v-else
      :tournament-in="tournament"
      :competitors="competitors"
      :place-list="placeList"
      :filtered-competitors="filteredCompetitors"/>
  </div>
</template>

<script>
import moment from "moment/moment";
import TeamClubResultsLinks from "./TeamClubResultsLinks.vue";
import FormSwitch from "../../partials/FormSwitch.vue";
import Dropdown from "../../partials/Dropdown.vue";
import CompetitorScore from "./CompetitorScore.vue";
import ResultFilters from "./ResultFilters.vue";
import {mapState, mapWritableState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";
import ResultFormSwitches from "./ResultFormSwitches.vue";
import ResultOptions from "./ResultOptions.vue";
import OverallResults from "./OverallResults.vue";
import {markRaw} from "vue";
import BreakdownResults from "./BreakdownResults.vue";
import LadderResults from "./LadderResults.vue";


/**
 * @typedef {object} tournamentIn
 * @property {string} name
 * @property {string} master_postal_id
 * @property {object} master
 * @property {string} dropped_rounds
 * @property {string} slug
 * @property {string} id
 * @property {string} tagline
 * @property {array} competitorList
 */

export default {
  name: "tournament-results",
  components: {
    LadderResults,
    BreakdownResults,
    OverallResults,
    ResultOptions,
    ResultFormSwitches,
    ResultFilters,
    Dropdown,
    FormSwitch,
    CompetitorScore,
    TeamClubResultsLinks
  },
  props: ['tournamentIn', 'user', 'updated'],
  data() {
    return {
      store: useResultsStore(),
      placeList: {overall: null, rounds: {}},
    }
  },
  watch: {
    updated(newVal, oldVal) {
      if (oldVal) {
        this.refreshing = false;
        this.showUpdated = true;
        this.setPlaceList();
        setTimeout(() => this.showUpdated = false, 3500)
      }
    }
  },
  created() {
    this.setStoreDefaults();
    this.checkForAndSetTournament();
    this.setPlaceList();
    this.setTeams();
    this.getDuplicates();
    this.store.setInitialFilters();
  },
  computed: {
    ...mapState(useResultsStore, [
      'round',
      'selectedCompetitor',
      'selectedTeam',
      'fullBreakdown',
      'search',
      'minimize',
      'breakdownArray',
      'showLadders',
    ]),
    ...mapWritableState(useResultsStore, [
      'fullSelectionLink',
      'teams',
      'refreshing',
      'showUpdated',
      'allDuplicates',
      'tieBreakers'
    ]),
    competitors() {
      return this.tournament.competitorList != null ? this.tournament.competitorList : [];
    },
    filteredCompetitors() {
      let round = this.round;
      let team = this.selectedTeam;
      let competitors = Object.values(this.competitors);
      if (this.search.length) {
        let search = this.search.toLowerCase();
        competitors = competitors.filter(function (competitor) {
          let name = competitor.name.toLowerCase();
          return name.includes(search);
        })
      }
      if (team) {
        competitors = competitors.filter(f => f.team ? f.team === team : false);
      }
      for (let option of Object.values(this.store.filterList)) {
        if (option.selected === null) continue;
        if (option.option === 'target') {
          competitors = competitors.filter(f => parseInt(f.target_id) === parseInt(option.selected.id));
        } else {
          competitors = competitors.filter(function (competitor) {
            return competitor.regform.find(function (detail) {
              return detail.option === option.option && detail.name === option.selected.label;
            })
          })
        }
      }
      let keyed = competitors.reduce((obj, key) => {
        obj[key.id] = key;
        return obj;
      }, {});
      return this.orderCompetitors(keyed, round);
    },
    showFilters() {
      return !this.selectedCompetitor && !this.fullBreakdown;
    },
    tournament() {
      return this.tournamentIn;
    },
    unassignedCompetitors() {
      let list = [];
      let tournament = this.tournamentIn;
      let competitors = this.tournamentIn.competitorList ?? [];
      Object.values(tournament.unassignedCompetitors.rounds).forEach(function (round) {
        Object.values(round.line_times).forEach(function (lineTime) {
          Object.values(lineTime.competitors).forEach(function (competitor) {
            let index = list.findIndex(p => p.id === competitor);
            if (index === -1) {
              list.push(competitors[competitor]);
            }
          })
        })
      });
      return list;
    },
    useHandicap() {
      if (this.tournament.is_league && this.tournament.handicap !== null) {
        return this.tournament.handicap.type.length && this.tournament.handicap.type !== 'None';
      }
      return false;
    },
    willDropRounds() {
      if (this.showLadders) return false;
      return this.tournament.is_league && this.tournament.dropped_rounds != null && this.tournament.dropped_rounds > 0;
    },
  },
  beforeMount() {
    this.setMeta();
  },
  methods: {
    checkForAndSetTournament() {
      if (this.tournament == null) {
        this.$store.commit('tournament/SET_TOURNAMENT', this.tournamentIn);
      }
    },
    getDuplicates() {
      this.$axios.get('/tournaments/' + this.tournament.slug + '/ordered_duplicates')
      .then(({data}) => {
        this.allDuplicates = markRaw(data.scores);
      })
    },
    orderCompetitors(competitors, round) {
      let scoreTotals = Object.keys(this.tournament.scoreList)
      .filter(key => Object.keys(competitors).includes(key))
      .reduce((obj, key) => {
        obj[key] = Object.values(round).length ? this.tournament.scoreList[key].rounds[round.id] : this.tournament.scoreList[key];
        obj[key]['id'] = key;
        return obj;
      }, {});
      let sorted = Object.values(scoreTotals);
      sorted.sort((a, b) => {
        let n = b.points - a.points;
        if (n !== 0) {
          return n
        }
        let found = 0;
        this.tieBreakers.forEach(breaker => {
          if (!found) {
            let compare = b[breaker] - a[breaker];
            if (compare !== 0) {
              found = compare;
            }
          }
          
        });
        return found;
      });
      sorted.forEach(function (competitor, index) {
        scoreTotals[competitor.id]['place'] = index + 1;
      });
      return sorted.map(m => {
        return m.id
      });
    },
    refresh() {
      this.refreshing = true;
      this.$emit('refresh');
    },
    setMeta() {
      let title = this.tournament.name + ' Tournament Results';
      let image = this.tournament.image && this.tournament.image_enabled ? this.tournament.image : null;
      let start = moment(new Date(this.tournament.start_time)).format('DD MMM');
      let end = moment(new Date(this.tournament.end_time)).format('DD MMM YYYY');
      let loc = '';
      if (this.tournament.address) {
        if (this.tournament.address.city) {
          loc += this.tournament.address.city;
          if (this.tournament.address.state) {
            loc += ', ';
          }
        }
        loc += this.tournament.address.state ? this.tournament.address.state : '';
      }
      let description = start + ' through ' + end + ' - ' +
        loc + '  ' + this.tournament.tagline;
      if (this.selectedCompetitor) {
        title = this.selectedCompetitor.name + ' Tournament Results';
      }
      document.querySelector('meta[property="og:description"]').setAttribute("content", description);
      document.querySelector('meta[property="og:title"]').setAttribute("content", title);
      if (image) {
        document.querySelector('meta[property="og:image"]').setAttribute("content", image);
      }
    },
    setPlaceList() {
      this.placeList['overall'] = this.orderCompetitors(this.competitors, {});
      this.tournament.rounds.forEach(round => {
        this.placeList['rounds'][round.id] = this.orderCompetitors(this.competitors, round);
      });
    },
    setStoreDefaults() {
      this.store.$patch({
        lineTime: this.tournamentIn.line_times[0],
        location: this.tournamentIn.locations[0],
        round: this.tournamentIn.rounds.length === 1 ? this.tournamentIn.rounds[0] : {},
        overall: this.tournamentIn.rounds.length !== 1,
        title: this.tournamentIn.name + ' Overall Results',
        fullSelectionLink: window.location.href,
        regform: this.tournamentIn.regform,
        useHandicap: this.useHandicap,
        tieBreakers: this.tournamentIn.tieBreakers,
        unassignedCompetitors: markRaw(this.unassignedCompetitors),
        tournamentName: this.tournamentIn.name,
        rounds: markRaw(this.tournamentIn.rounds),
        targetValues: markRaw(this.tournamentIn.targetValues),
        targetStyle: this.tournamentIn.target_style,
        isLeague: this.tournamentIn.isLeague,
        handicap: this.tournamentIn.handicap,
        targets: markRaw(this.tournamentIn.targets),
      })
    },
    setTeams() {
      this.teams = this.$store.getters['tournament/getTeams'];
    },
  }
}
</script>

<style scoped>
th, td {
  width: 1px;
  text-align: center;
}

table {
  position: relative;
  overflow: visible;
}

table th {
  position: sticky;
  top: 0;
}

.minimized th,
.minimized :deep( td) {
  padding: 0 3px 0 3px;
}

.minimized table {
  width: auto;
  margin: auto;
}

.selectedTab {
  @apply text-xl text-gray-800 border-t-4 border-blue-600 ;
}


.fade-leave-active, .fade-enter-active {
  transition: opacity 1s;
}

.fade-enter-from, .fade-leave-to, .fade-leave /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  
}
</style>
