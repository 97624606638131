import {defineStore} from "pinia";

export const useResultsStore = defineStore('results', {
  state: () => {
    return {
      lineTime: null,
      location: null,
      end: 1,
      round: {},
      overall: null,
      title: null,
      selectedRound: null,
      selectedCompetitor: null,
      selectedTeam: null,
      fullBreakdown: false,
      search: '',
      fullSelectionLink: null,
      printing: false,
      teams: [],
      orderedC: 0,
      refreshing: false,
      showUpdated: false,
      minimize: false,
      breakdownArray: {},
      limitBreak: false,
      allDuplicates: null,
      count: 0,
      regform: null,
      useHandicap: false,
      tieBreakers: null,
      unassignedCompetitors: [],
      tournamentName: '',
      rounds: null,
      targetValues: null,
      targetStyle: null,
      isLeague: false,
      handicap: null,
      targets: null,
      showLadders: false,
      filterList: {},
    };
  },

  getters: {
    divisionArray(state) {
      let options = state.regform.find(detail => detail.option === 'division');
      if (options && options.enabled) {
        return options.multiple;
      } else {
        return [null];
      }
    },
    classArray(state) {
      let options = state.regform.find(detail => detail.option === 'class');
      if (options && options.enabled) {
        return options.multiple
      } else {
        return [null];
      }
    },
    genderArray(state) {
      let options = state.regform.find(detail => detail.option === 'gender');
      if (options && options.enabled) {
        return options.multiple
      } else {
        return [null];
      }
    },

    longestRound(state) {
      let count = 0;
      let nope = false;
      state.rounds.forEach(round => round.ends_count > count ? count = round.ends_count : nope = true);
      return count;
    },

    filteredUnassigned(state) {
      let competitors = state.unassignedCompetitors;
      if (state.search.length) {
        let search = state.search.toLowerCase();
        competitors = competitors.filter(function (competitor) {
          let name = competitor.name.toLowerCase();
          return name.includes(search);
        })
      }
      return competitors;
    },
  },
  actions: {
    setInitialFilters() {
      if (Object.keys(this.filterList).length) return;
      let list = {};
      for (let form of this.regform) {
        if (form.enabled && form.multiple != null) {
          list[form.option] = {
            'name': form.name,
            'option': form.option,
            'id': form.id,
            'multiple': form.multiple, // EX: [{id:1, label: 'option 1'}]
            'selected': null,
          }
        }
      }
      if (this.targets.length > 1) {
        let multiple = [];
        for (let target of this.targets) {
          multiple.push({'id': target.id, 'label': target.name})
        }
        list['target'] = {
          'name': 'Target',
          'option': 'target',
          'id': null,
          'multiple': multiple,
          'selected': null
        };
      }
      this.filterList = list;
    },
    async updateUrl() {
      let filters = {};
      if (!this.overall) {
        filters['round'] = this.round.slug;
      }
      if (this.selectedTeam) {
        filters['team'] = this.selectedTeam;
      }
      for (let option of Object.values(this.filterList)) {
        if (option.selected === null) continue;
        filters[option.option] = option.selected.label;
      }
      if (this.selectedCompetitor) {
        filters['competitor'] = this.selectedCompetitor.id;
      }
      if (this.search) {
        filters['search'] = this.search;
      }
      await this.router.push({query: filters, replace: true}).catch(() => {
      });
      this.fullSelectionLink = window.location.href;
    },
    competitorDuplicates(competitorId) {
      if (this.allDuplicates === null) return [];
      if (competitorId in this.allDuplicates) {
        return this.allDuplicates[competitorId];
      }
      return [];
    },
  },
})